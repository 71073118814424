import React from "react";
import {Link} from "gatsby";

import Body from "components/layout/Body/Body";
import SEO from "../components/seo";

const CV = () => (
	<>
		<SEO title="CV - Kevin Mamaqi Kapllani" />
		<h1>CV</h1>
		<p>My CV.</p>
		<Link to="/">Go back to the homepage</Link>
	</>
);

CV.Layout = Body;

export default CV;
